import Vue from "vue";
import Vuex from "vuex";
import updater from "./modules/updater";
import packs from "./modules/packs";
import auth from "./modules/auth";
import project from "./modules/project";
import test from "@/store/modules/test";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        project,
        packs,
        updater,
        test
    },
    state: {
        isLoading: false,
        testMode: false
    },
    mutations: {
        setLoading(state, status) {
            state.isLoading = status;
        },
        testMode(state, status) {
            state.testMode = status;
        }
    },
    actions: {
        loading(context, status) {
            context.commit("setLoading", status);
        }
    }
});
