import { PACKS_CLEAR, PACKS_SET } from "@/store/actions/packs";

export default {
    state: {
        drug: null
    },
    mutations: {
        [PACKS_SET]: (state, data) => {
            state.drug = data;
        }
    },
    actions: {
        [PACKS_SET]: ({ commit }, drug) => {
            commit(PACKS_SET, drug);
        },
        [PACKS_CLEAR]: ({ commit }) => {
            commit(PACKS_SET, null);
        }
    }
};
