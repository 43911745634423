<template>
    <v-app>
        <v-updater />
        <v-overlay :value="$store.state.isLoading" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>
<script>
import VUpdater from "@/VUpdater";

export default {
    components: { VUpdater }
};
</script>
