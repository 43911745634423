/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from "axios";
import {
    AUTH_ERROR,
    AUTH_LOGOUT,
    AUTH_REQUEST,
    AUTH_SUCCESS
} from "../actions/auth";

const state = {
    token: localStorage.getItem("user-token") || "",
    status: "",
    admin: localStorage.getItem("user-admin") || ""
};

const getters = {
    isAuthenticated: state => !!state.token,
    isAdmin: state => state.admin === "true",
    authStatus: state => state.status
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) =>
        new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios
                .post("/api/login", {
                    name: user.name,
                    password: user.password
                })
                .then(resp => {
                    localStorage.setItem("user-token", resp.data.access_token);
                    localStorage.setItem("user-admin", resp.data.user.admin);
                    axios.defaults.headers.common.Authorization = `Bearer ${resp.data.access_token}`;
                    commit(AUTH_SUCCESS, resp.data);
                    resolve(resp.data);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    localStorage.removeItem("user-token");
                    localStorage.removeItem("user-admin");
                    reject(err.response);
                });
        }),
    [AUTH_LOGOUT]: ({ commit }) =>
        new Promise(resolve => {
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-admin");
            commit(AUTH_LOGOUT);
            resolve();
        })
};
const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, data) => {
        state.status = "success";
        state.admin = data.user.admin;
        state.token = data.access_token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
        state.admin = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
