export const PROJECT_SET = "PROJECT_SET";
export const DRUGINFO_GET = "DRUGINFO_GET";
export const PACKINFO_GET = "PACKINFO_GET";
export const KSGPROJECT_GET = "KSGPROJECT_GET";
export const DRUGINFO_SET = "DRUGINFO_SET";
export const PACKINFO_SET = "PACKINFO_SET";
export const KSGPROJECT_SET = "KSGPROJECT_SET";
export const DRUGINFO_RESET = "DRUGINFO_RESET";
export const PACKINFO_RESET = "PACKINFO_RESET";
export const KSGPROJECT_RESET = "KSGPROJECT_RESET";
