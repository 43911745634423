import UPDATE_NOTIFICATION_SET from "@/store/actions/updater";

let version;
if (window.check_client_version) {
    version = window.check_client_version();
} else {
    version = "v1.1.0 build 4"; //undefined;
}
const accepted =
    JSON.parse(localStorage.getItem("skip-notification")) === true || false;
export default {
    state: {
        accepted,
        notification: !accepted,
        version
    },
    mutations: {
        [UPDATE_NOTIFICATION_SET]: (state, data) => {
            state.notification = data;
        }
    },
    actions: {},
    getters: {
        version: state =>
            state.version === "v1.1.0 build 4" ? "updated" : "old"
    }
};
