<template>
    <v-dialog
        :value="
            $store.state.updater.notification &&
                $store.getters.version === 'old'
        "
        persistent
        fullscreen
    >
        <v-overlay :value="isLoading" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card>
            <v-card-title class="headline justify-center">
                Необходимо обновить клиент
            </v-card-title>
            <v-card-text class="text--primary text-center py-0">
                <p class="text-center">
                    Несмотря на то, что бóльшая часть обновлений проходит
                    незаметно для Вас, иногда, в исключительно редких случаях
                    нам необходимо чтобы Вы вручную обновили клиент. <br />
                    Это способствует стабильности системы, исправляет различные
                    ошибки отображения и прочее.
                </p>

                <p>
                    Для начала скачайте архив новой версии клиента:
                </p>
                <v-btn tile color="primary" @click="getClient">
                    Скачать
                </v-btn>
                <p class="mt-4">
                    Теперь вам необходимо разархивировать файл в корень вашей
                    флешки и подтвердить замену:
                </p>
                <v-img
                    src="@/assets/ClientUpdateGIF.gif"
                    max-width="1000px"
                    class="mx-auto"
                />
                <p class="mt-4">
                    <b class="warning">ВНИМАНИЕ!</b>
                    Все операции копирования и замены можно выполнять только
                    когда клиент не запущен!
                </p>
            </v-card-text>
            <v-card-actions class="mx-4 py-0">
                <v-checkbox
                    :false-value="false"
                    :input-value="$store.state.updater.accepted"
                    :true-value="true"
                    label="Больше не показывать"
                    @change="changeAccepted"
                />
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeUpdater">
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import UPDATE_NOTIFICATION_SET from "@/store/actions/updater";

export default {
    name: "v-updater",
    data: () => ({
        isLoading: false
    }),
    methods: {
        getClient() {
            this.isLoading = true;
            this.axios
                .get("/api/getClient", { responseType: "blob" })
                .then(resp => {
                    const blob = new Blob([resp.data]);
                    const filename = resp.headers["content-disposition"]
                        .split("filename=")[1]
                        .split(";")[0];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    this.isLoading = false;
                });
        },
        closeUpdater() {
            this.$store.commit(UPDATE_NOTIFICATION_SET, false);
        },
        changeAccepted(val) {
            localStorage.setItem("skip-notification", val);
        }
    }
};
</script>
