export default {
    state: {
        schemes: localStorage.getItem("plain-schemes")?.split(",") || [],
        plainSchemes: null
    },
    mutations: {
        setSchemeList: (state, payload) => {
            state.schemes = payload;
            localStorage.setItem("plain-schemes", payload);
        },
        setPlainSchemes: (state, payload) => {
            state.plainSchemes = payload;
        }
    }
};
