import {
    DRUGINFO_GET,
    DRUGINFO_RESET,
    DRUGINFO_SET,
    KSGPROJECT_GET,
    KSGPROJECT_RESET,
    KSGPROJECT_SET,
    PACKINFO_GET,
    PACKINFO_RESET,
    PACKINFO_SET,
    PROJECT_SET
} from "@/store/actions/project";
import axios from "axios";

export default {
    state: {
        project: null,
        drugInfo: null,
        packInfo: null,
        ksgProject: null
    },
    mutations: {
        [PROJECT_SET]: (state, data) => {
            state.project = data;
        },
        [DRUGINFO_SET]: (state, data) => {
            state.drugInfo = data;
        },
        [PACKINFO_SET]: (state, data) => {
            state.packInfo = data;
        },
        [KSGPROJECT_SET]: (state, data) => {
            state.ksgProject = data;
        },
        [DRUGINFO_RESET]: state => {
            state.drugInfo = null;
        },
        [PACKINFO_RESET]: state => {
            state.packInfo = null;
        },
        [KSGPROJECT_RESET]: state => {
            state.ksgProject = null;
        }
    },
    actions: {
        [PROJECT_SET]: ({ commit, state }, project) => {
            if (state.project?.id !== project?.id) {
                commit(DRUGINFO_RESET);
                commit(PACKINFO_RESET);
                commit(KSGPROJECT_RESET);
            }
            commit(PROJECT_SET, project);
        },
        [DRUGINFO_GET]: ({ commit, state, dispatch }) =>
            new Promise(resolve => {
                dispatch("loading", true);
                axios
                    .get(`/api/projects/${state.project.id}/drugs`)
                    .then(resp => {
                        const drugInfo = resp.data;
                        commit(DRUGINFO_SET, drugInfo);
                        dispatch("loading", false);
                        resolve();
                    });
            }),
        [PACKINFO_GET]: ({ commit, state, dispatch }) =>
            new Promise(resolve => {
                dispatch("loading", true);

                const packBase = axios.get(
                    `/api/projects/${state.project.id}/pack-base`
                );
                const packs = axios.get(
                    `/api/projects/${state.project.id}/packs`
                );
                Promise.all([packBase, packs]).then(results => {
                    const packInfo = {};
                    packInfo.packBase = results[0].data;
                    packInfo.packs = results[1].data;
                    commit(PACKINFO_SET, packInfo);
                    dispatch("loading", false);
                    resolve();
                });
            }),
        [KSGPROJECT_GET]: ({ commit, state, dispatch }) =>
            new Promise(resolve => {
                dispatch("loading", true);
                axios
                    .get(`/api/projects/${state.project.id}/ksg-info`)
                    .then(resp => {
                        const ksgProject = resp.data;
                        commit(KSGPROJECT_SET, ksgProject);
                        dispatch("loading", false);
                        resolve();
                    });
            }),
        [KSGPROJECT_SET]: ({ commit }, ksgProject) => {
            commit(KSGPROJECT_SET, ksgProject);
        }
    },
    getters: {
        projectName: state => (state.project ? state.project.name : "")
    }
};
