import Vue from "vue";
import axios from "axios";
import vueaxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "core-js/stable";
import "regenerator-runtime/runtime";

Vue.config.productionTip = false;
if (store.getters.isAuthenticated) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
}
Vue.use(vueaxios, axios);
Vue.mixin({
    methods: {
        amountNormalized(amount) {
            return `${amount.toLocaleString("ru-RU")}\xa0шт.`;
        },
        unitNormalized(unit) {
            return `${(Math.round(unit * 100) / 100).toLocaleString("ru-RU")}`;
        },
        priceNormalized(price) {
            return `${(Math.round(price * 100) / 100).toLocaleString("ru-RU", {
                minimumFractionDigits: 2
            })}\xa0₽`;
        },
        getPackPrice(pack) {
            let { price } = pack.prices;
            if (!this.$store.state.project.project.vat) price *= 0.9;
            if (this.$store.state.project.project.rat > 0) {
                price *= 1 + this.$store.state.project.project.rat / 100;
            }
            return price;
        }
    }
});
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
