import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import { PROJECT_SET } from "@/store/actions/project";

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        redirect: "/"
    },
    {
        path: "/",
        component: Home
    },
    {
        path: "/test",
        component: () => import("../components/TestComponent/PlainSchemes.vue")
    },
    {
        path: "/test-options",
        component: () =>
            import("../components/TestComponent/PlainSchemesOptions")
    },
    {
        path: "/test-results",
        component: () =>
            import("../components/TestComponent/PlainSchemesResults")
    },
    {
        path: "/login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/dashboard",
        component: () => import("../views/Dashboard.vue"),
        children: [
            {
                path: "",
                component: () => import("../views/Dashboard/ProjectList.vue")
            },
            {
                path: "add",
                component: () => import("../views/Dashboard/ProjectAdd.vue")
            },
            {
                path: ":id",
                component: () => import("../views/Dashboard/Project.vue"),
                name: "Project"
            }
        ]
    },
    {
        path: "/help",
        component: () => import("../views/About.vue")
    },
    {
        path: "/admin",
        component: () => import("../views/Admin.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (from.name === "Project") store.dispatch(PROJECT_SET, null);
    if (
        store.getters.isAuthenticated &&
        (to.path === "/" || to.path === "/login")
    ) {
        next("/dashboard");
    } else if (
        !store.getters.isAuthenticated &&
        to.path !== "/" &&
        to.path !== "/login"
    ) {
        next("/login");
    } else {
        next();
    }
});

export default router;
